import { Component, DoCheck, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { MDBSpinningPreloader, ModalDirective } from 'ng-uikit-pro-standard';
import { Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

import { User } from '@app/models/user.model';
import { AuthService } from '@app/services/auth.service';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { LoaderService, LoaderState } from './services/loader.service';
import { Subscription } from 'rxjs/index';
import { BasicConfigService } from './services/basic_config.service';
import { LegalService } from './services/legal.service';
import { RoutingState } from './services/routing-state.service';
import {ApolloService} from "@app/services/apollo_socket.service";
import {WebSocketService} from "@app/services/web_socket.service";

export interface BreadCrumb {
  label: string;
  url: string;
}

declare let ga: Function;
declare function addToHomescreen(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ BasicConfigService, LegalService, RoutingState, WebSocketService, ApolloService ]
})
export class AppComponent implements DoCheck, OnInit {
  public userIdentity: User;
  public breadcrumbs = [];
  public showContent = false;
  public subscription: Subscription = null;
  private show_notification_modal = true;
  public cookies = '';
  public showBreadcrumb = false;
  loaderColor = 'white';
  googleAnalytics = '';
  public promptEvent = null;

  @ViewChild('frame', { static: true }) pop: ModalDirective;
  @ViewChild('cookiesModal', { static: true }) cookiesModal: ModalDirective = null;
  readonly PUBLIC_KEY = 'BIZ8Md9cPMpSln4R6J9mSpI_N3qSsEGPZmf2fjUw7rVkFD0edx6kAuu2XfWDErt_JsnZ9lRusBR9-IJmFAWIf-A';

  constructor(private auth: AuthService,
              private loaderService: LoaderService,
              private basicConfigService: BasicConfigService,
              private legalService: LegalService,
              private titleService: Title,
              private mdbSpinningPreloader: MDBSpinningPreloader,
              private translate: TranslateService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private renderer: Renderer2,
              private swPush: SwPush,
              private swUpdate: SwUpdate,
              private el: ElementRef,
              private apolloService: ApolloService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');
    registerLocaleData( es );

    /*setTimeout(function(){
      apolloService.messages.next({type:"manage_session", session_id: 1, action: "question_to_audience_status", question_to_audience: 1, status: true})
    }, 15000)

    setTimeout(function(){
      apolloService.messages.next({type:"manage_session", session_id: 1, action: "question_to_audience_status", question_to_audience: 1, status: false})
    }, 20000)

    setTimeout(function(){
      apolloService.messages.next({type:"manage_session", session_id: 1, action: "question_to_audience_status", question_to_audience: 2, status: true})
    }, 25000)*/


    /*setInterval(function() {
      apolloService.messages.next({type:"ping"});
    }, 5000);*/

  }
  private reconnect() {
    this.apolloService.reconnect();
  }

  ngDoCheck() {
    this.userIdentity = this.auth.getUserIdentity();

    if (this.userIdentity && this.pop && this.show_notification_modal && 'Notification' in window) {
      this.show_notification_modal = false;
      if (!this.auth.isSubscribed()) {
        setTimeout(function() {
          this.pop.show();
        }.bind(this), 3000);
      }
    }
  }

  ngOnInit() {
    addToHomescreen();

    setTimeout(() => this.auth.getUser(null), 1)
    setTimeout(() => this.loadConfig(), 10)
    

    this.generateBreadcrumbs();

    this.checkForUpdates();

    /*this.swPush.messages.subscribe((data: any) => {
      const notification = data.notification;
      console.log('received push message', data);

      if (notification) {
        const options = {
          title: notification.title,
          body: notification.body,
          icon: 'assets/icon.png',
          data: notification.data,
        };
        this.showNotification(notification.title, options);
      }
    });*/

    this.mdbSpinningPreloader.stop();
    this.showBodyAfterFirstRequest();
  }

  showNotification(title: string, options: NotificationOptions) {
    this.auth.subscribe();
    navigator.serviceWorker.getRegistration().then(reg => {
      reg.showNotification(title, options).then(res => {
        console.log('showed notification', res);
      }, err => {
        console.error(err);
      });
   });
  }

  loadConfig() {
    this.basicConfigService.list(function() {
      this.applyConfiguration();
    }.bind(this));
  }

  applyConfiguration() {
    const skin = this.basicConfigService.getProperty('skin');
    if (skin) {
      document.body.classList.forEach(obj => {
        if (obj.endsWith('-skin')) {
          this.renderer.removeClass(document.body, obj);
        }
      });
      this.renderer.addClass(document.body, skin);
    }
    // Loader
    this.loaderColor = this.basicConfigService.getProperty('loaderColor');
    // Favicon
    const favicon = this.basicConfigService.getProperty('favicon');
    const faviconType = this.basicConfigService.getProperty('faviconType');
    if (favicon !== '' && faviconType !== '') {
      const linkElement = document.getElementById('favicon');
      linkElement.setAttribute('rel', 'icon');
      linkElement.setAttribute('type', faviconType );
      linkElement.setAttribute('href', favicon );
    }
    // Title
    this.titleService.setTitle(this.basicConfigService.getProperty('title'));
    // cookies
    if (!this.auth.areCookiesAccepted() && this.basicConfigService.getProperty('cookiesDisclaimer') === '1') {
      this.showCookiesModal();
    }
    // google analytics
    this.addGoogleAnalytics();
    // Breadcrumb
    this.showBreadcrumb = this.basicConfigService.getProperty('showBreadcrumb') === '1';
  }

  showBodyAfterFirstRequest() {
    this.subscription = this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
      this.showContent = true;
      this.subscription.unsubscribe();
    });
  }

  checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
          if (confirm('Hay una nueva versión de la web. ¿Quieres actualizarla?')) {
              window.location.reload();
          }
      });
    }
  }

  private generateBreadcrumbs() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      });
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '/',
                breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data[ 'breadcrumb' ] : false;
    const path = route.routeConfig ? route.routeConfig.path : '';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    let nextUrl = url;
    if (path !== '') {
      nextUrl += path + '/';
    }
    let newBreadcrumbs: any;
    if (label) {
      const parameters: any = route.params;
      const params = parameters._value;
      for (const key of Object.keys(params)) {
        nextUrl = nextUrl.replace(':' + key, params[key]);
      }
      const breadcrumb = {
          label: label,
          url: nextUrl
      };
      newBreadcrumbs = [ ...breadcrumbs, breadcrumb ];
    } else {
      newBreadcrumbs = breadcrumbs;
    }
    if (route.firstChild) {
        // If we are not on our current path yet,
        // there will be more children to look after, to build our breadcumb
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  /*subscribeToNotifications() {
    this.swPush.requestSubscription({
        serverPublicKey: this.PUBLIC_KEY
    })
    .then(sub => {
      this.auth.sendSubscription(sub).subscribe(
        response => { },
        error => { console.error('Could not subscribe to notifications', error);
      });
    })
    .catch(err => {console.error('Could not subscribe to notifications due to browser error', err);
    console.log(err); } );
    this.createSubscribeCookie();
  }

  createSubscribeCookie() {
    this.auth.subscribe();
  }*/

  addGoogleAnalytics() {
    this.googleAnalytics = this.basicConfigService.getProperty('googleAnalyticsTrackId');
    if (this.googleAnalytics && this.googleAnalytics !== '') {
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function() {
          (i[r].q = i[r].q || []).push(arguments);
        };
        i[r].l = (new Date()).getTime();
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
      ga('create', this.googleAnalytics, 'auto');
      ga('send', 'pageview');
    }
  }

  acceptCookies() {
    this.cookiesModal.hide();
    this.auth.acceptCookies();
  }
  showCookiesModal() {
    if (this.router.url.indexOf('/legal') === -1) {
      this.cookiesModal.show();
      this.legalService.get('cookiesConsent').subscribe(response => {
        if (response.status === 0 && response.data && response.data.text) {
          this.cookies = response.data.text;
        }
      });
    }
  }

}
