import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GLOBAL } from '@app/global';

import { Notification } from '../models/notification.model';
import { Observable } from 'rxjs/index';

interface Response {
  status: number;
  data: any;
  repeatInterval?: number;
}

@Injectable()
export class NotificationService {
  public url: string;

  constructor(private _http: HttpClient) {
     this.url = GLOBAL.url;
  }

  list() {
    return this._http.get<Response>(this.url + '-/auth/notification');
  }

  count() {
    return this._http.get<Response>(this.url + '-/auth/notification/new/count');
  }

  read(notification: Notification) {
    return this._http.post<Response>(this.url + '-/auth/notification/' + notification.id + '/read', null);
  }
}
