export const GLOBAL = {
  //baseUrl: 'https://allergan.apollosuniverse.com/back/',
  //baseUrl: 'https://app.aesmatronas.com/backend/',
  // baseUrl: 'http://192.168.0.157:8888/apollo/',
  baseUrl: '/',
  //baseUrl: 'http://localhost:4200/apollo/',
  url: '',
  skin: 'white-skin', // TODO: quitar
  uploads: '',
  profileUploads: '',
  speakerUploads: '',
  eventUploads: '',
  challengeUploads: '',
  title: 'APOLLO',
  path: {
    assets: './assets/'
  },
  // general configuration
  event: {
    default: null, // ex: 'Prueba' or null TODO: quitar
    challenge: {
      default: null, // ex: 1 or null
    }
  }
};
GLOBAL.url = GLOBAL.baseUrl + 'api/v1/';
GLOBAL.uploads = GLOBAL.baseUrl + 'uploads/';
GLOBAL.profileUploads = GLOBAL.uploads + 'profile/';
GLOBAL.speakerUploads = GLOBAL.uploads + 'speaker/';
GLOBAL.eventUploads = GLOBAL.uploads + 'event/';
GLOBAL.challengeUploads = GLOBAL.uploads + 'challenge/';
