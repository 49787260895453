import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GLOBAL } from '@app/global';

import { User } from '@app/models/user.model';

interface Response {
  status: number;
  data: any;
}

@Injectable()
export class AuthService {
  public static emitter: EventEmitter<void> = new EventEmitter();
  public url: string;

  constructor(private _http: HttpClient) {
     this.url = GLOBAL.url;
  }

  login(user: User, callback: Function, errorCallback: Function = function(error) { console.log(error); }) {
    const params = JSON.stringify(user);

    return this._http.post<Response>(this.url + 'auth/login', params).subscribe(
      response => {
        if (response.data && response.data.token) {
          this.set(response.data.token);
        }
        this.getUser(callback);
      },
      error => {
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }

  logout() {
    this.softLogout();
    this._http.delete(this.url + 'auth/invalidate').subscribe(
      response => { },
      error => { console.log(<any>error); });
  }

  public softLogout() {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtImpersonate');
    localStorage.removeItem('jwtUserIdentity');
    localStorage.removeItem('jwtUserActions');
    localStorage.removeItem('jwtUserEventActions');
  }

  getUser(callback: Function) {
    this._http.get<Response>(this.url + '-/auth/user')
          .subscribe(
            response => {
              if (response.data) {
                const user = new User();
                user.id = response.data.id;
                user.email = response.data.email;
                user.name = response.data.name;
                user.surname1 = response.data.surname1;
                user.surname2 = response.data.surname2;
                user.image = response.data.image;
                this.setUserIdentity(user);
                this.setUserActions(response.data.actions);
                if (callback != null) {
                  callback();
                }
              }
            },
            error => { console.log(<any>error); }
          );
  }

  public refreshToken() {
    return this._http.patch<Response>(this.url + 'auth/refresh', null);
  }

  public set(token: string) {
    localStorage.setItem('jwtToken', token);
  }

  public subscribe() {
    localStorage.setItem('browserSubscribed', '1');
  }

  public unsubscribe() {
    localStorage.setItem('browserSubscribed', '');
  }

  public acceptCookies() {
    localStorage.setItem('cookiesAccepted', '1');
  }

  public areCookiesAccepted() {
    return localStorage.getItem('cookiesAccepted') === '1';
  }

  public isSubscribed() {
    return localStorage.getItem('browserSubscribed') === '1';
  }

  public impersonate() {
    localStorage.setItem('jwtImpersonate', '1');
  }

  public isImpersonated() {
    return localStorage.getItem('jwtImpersonate') === '1';
  }

  public get(): string {
    return localStorage.getItem('jwtToken');
  }

  public getUserIdentity(): User {
    return JSON.parse(localStorage.getItem('jwtUserIdentity'));
  }

  public setUserIdentity(userIdentity: User) {
    localStorage.setItem('jwtUserIdentity', JSON.stringify(userIdentity));
  }

  public getUserActions(): String[] {
    return JSON.parse(localStorage.getItem('jwtUserActions'));
  }

  public setUserActions(userActions: String[]) {
    localStorage.setItem('jwtUserActions', JSON.stringify(userActions));
  }

  public setUserEventActions(userEventActions: string[]) {
    localStorage.setItem('jwtUserEventActions', JSON.stringify(userEventActions));
  }

  update(user: User) {
    const params = JSON.stringify(user);
    return this._http.patch<Response>(this.url + '-/me', params);
  }

  updatePassword(password: string) {
    const params = JSON.stringify({'password': password});
    return this._http.patch<Response>(this.url + '-/me/password', params);
  }

  updateProfileImage(base64Image: string) {
    const params = JSON.stringify({'base64_image': base64Image});
    return this._http.post<Response>(this.url + '-/me/profileImage', params);
  }

  sendSubscription(payload: PushSubscription) {
    const params = JSON.stringify(payload);
    console.log(params);
    return this._http.post<Response>(this.url + '-/me/subscribe/browser', params);
  }
}
