import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import {ChangeDetectorRef, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';

import { User } from '@app/models/user.model';

import {AuthService} from '@app/services/auth.service';
import { GLOBAL } from '@app/global';
import { UserCircleComponent } from '@app/modules/_shared/components/user_circle/user_circle.component';
import { BaseComponent } from '../baseComponent';
import { ToastService } from 'ng-uikit-pro-standard';
import { NotificationService } from '@app/modules/user/services/notification.service';
import { BasicConfigService } from '@app/services/basic_config.service';
import { Location } from '@angular/common';
import { RoutingState } from '@app/services/routing-state.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
      AuthService,
      NotificationService,
      BasicConfigService,
      RoutingState
    ]
})

export class NavbarComponent extends BaseComponent implements OnInit, DoCheck, OnDestroy  {
  public userIdentity: User;
  public global;
  public impersonated = false;
  public unread_notifications = 0;
  public unread_messages = 0;
  public icon = '';
  public logo = '';
  public logoText = '';
  public showSidebar = false;
  public hasHistory = false;
  @ViewChild('userCircle', { static: false }) userCircle: UserCircleComponent;

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);
  fillerContent = Array.from({length: 50}, () =>
  `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
   labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
   laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
   voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
   cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private authService: AuthService,
    private notificationService: NotificationService,
    private configService: BasicConfigService,
    private router: Router,
    public toastr: ToastService,
    private routingState: RoutingState,
    private _location: Location) {
    super(toastr);
    this.global = GLOBAL;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/user/login']);
  }

  ngOnInit() {
    this.icon = this.configService.getProperty('icon');
    this.logo = this.configService.getProperty('logo');
    this.logoText = this.configService.getProperty('logoText');
    this.showSidebar = this.configService.getProperty('showSidebar') === '1';
    AuthService.emitter.subscribe(
      () => {
        this.userIdentity = this.authService.getUserIdentity();
        if (this.userCircle) {
          this.userCircle.userImage = this.userIdentity.image;
          this.userCircle.updateImage();
        }
      }
    );
    setTimeout(() => this.unreadNotifications(), 3000);
    // load routing
    this.routingState.loadRouting();
  }

  ngDoCheck() {
    this.hasHistory = this.routingState.hasHistory();
    const newId = this.authService.getUserIdentity();
    this.userIdentity = newId;
    this.impersonated = this.authService.isImpersonated();
  }

  unreadNotifications() { 
    if (this.userIdentity) {
      this.notificationService.count().subscribe(
        response => {
          if (response.data) {
            this.unread_notifications = response.data.unread;
            this.unread_messages = response.data.unread_messages;
          }
          if (response.repeatInterval > 0){
            setTimeout(() => this.unreadNotifications(), response.repeatInterval);
          }
        },
        error => {this.handleError(error, 'Notificaciones'); });
    }
  }

  goBack() {
    if (this.hasHistory) {
      this._location.back();
    }
  }
}
