export class User {
  public id: number = null;
  public email: string = null;
  public password: string = null;
  public name: string = null;
  public surname1: string = null;
  public surname2: string = null;
  public image: string = null;
  public active: boolean = false;
  public salesforce_account_id: string = null;
  public extra: any = null;

  get fullName(): string {
    return this.name + ' ' + this.surname1 + ' ' + this.surname2;
  }

  toString(): string {
    return this.fullName;
  }
}
